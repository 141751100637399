import { inject, Injectable, makeStateKey } from '@angular/core';
import { StoreApiService } from './store-api.service';
import { Router } from '@angular/router';
import { components } from '../api-types/store-overrides';
import { Schemas } from '../api-types/storeApiTypes';
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  readonly storeApi = inject(StoreApiService);
  readonly router = inject(Router);
  readonly analytics = inject(AnalyticsService);

  store = this.storeApi.createApiStore(
    makeStateKey<components['schemas']['Cart']>('cart'),
    () =>
      this.storeApi.apiClient
        .invoke('readCart get /checkout/cart', {})
        .then((r) => r.data),
  );

  async addToCart(product: Schemas['Product'], quantity: number) {
    const current = this.store();
    const existing = !current.loading
      ? current.lineItems?.find((l) => l.referencedId === product.id)
      : undefined;
    if (!existing) {
      const cart = await this.storeApi.apiClient.invoke(
        'addLineItem post /checkout/cart/line-item',
        {
          body: {
            items: [
              {
                type: 'product',
                referencedId: product.id,
                quantity,
              },
            ],
          },
        },
      );
      this.updateStore(cart);
      this.addLineItem(cart.data, product.id);
    } else {
      await this.updateQuantity(existing, existing.quantity + quantity);
    }
  }

  private updateStore(cart: { data: Schemas['Cart'] }) {
    this.store.update((state) => {
      return {
        ...state,
        ...cart.data,
        promise: Promise.resolve(cart.data),
        loading: false,
      };
    });
  }

  private addLineItem(cart: components['schemas']['Cart'], productId: string) {
    const item = cart.lineItems?.find((l) => l.referencedId === productId);
    if (item) {
      this.analytics.event('add_to_cart', {
        currency: 'EUR',
        value: item.price?.totalPrice,
        items: [
          {
            item_id: item.referencedId,
            item_name: item.label,
            index: 0,
            price: item.price?.unitPrice ?? 0,
            quantity: item.quantity,
          },
        ],
      });
    }
  }

  private removeLineItem(item: Schemas['LineItem']) {
    this.analytics.event('add_to_cart', {
      currency: 'EUR',
      value: item.price?.totalPrice ?? 0,
      items: [
        {
          item_id: item.referencedId,
          item_name: item.label,
          index: 0,
          price: item.price?.unitPrice ?? 0,
          quantity: item.quantity,
        },
      ],
    });
  }

  async addPromotionCode(code: string) {
    const cart = await this.storeApi.apiClient.invoke(
      'addLineItem post /checkout/cart/line-item',
      {
        body: {
          items: [
            {
              type: 'promotion',
              referencedId: code,
            },
          ],
        },
      },
    );
    this.updateStore(cart);
  }

  async updateQuantity(item: Schemas['LineItem'], quantity: number) {
    if (quantity <= 0) {
      const cart = await this.storeApi.apiClient.invoke(
        'removeLineItem post /checkout/cart/line-item/delete',
        {
          body: { ids: [item.id] },
        },
      );
      this.updateStore(cart);
      this.removeLineItem(item);
    } else {
      const cart = await this.storeApi.apiClient.invoke(
        'updateLineItem patch /checkout/cart/line-item',
        {
          body: {
            items: [
              {
                id: item.id,
                quantity,
              },
            ],
          },
        },
      );
      this.updateStore(cart);
      this.addLineItem(cart.data, item.referencedId ?? '');
    }
  }
}
