import {signal, WritableSignal} from "@angular/core";

export type ApiState<T> = T & {
  loading: false, promise: Promise<T>
} | { loading: true, promise: Promise<T> };

export function apiStore<T>(val: Promise<T>): WritableSignal<ApiState<T>> {
  const state = signal<ApiState<T>>({loading: true, promise: val})
  val.then((res) => {
    if (res) {
      state.set({
        ...res,
        loading: false,
        promise: Promise.resolve(res),
      })
    }
  })
  return state;
}
