import { Directive, HostListener, inject, input } from '@angular/core';
import { EventService } from './event.service';

@Directive({
  selector: '[appEvent]',
  standalone: true,
})
export class EventDirective {
  appEvent = input.required<string>();
  appEventData = input<unknown>({});

  eventState = inject(EventService);

  @HostListener('click')
  onClick() {
    this.eventState.push(this.appEvent(), this.appEventData());
  }
}
