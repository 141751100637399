import { Signal, signal, StateKey, TransferState } from '@angular/core';

export interface ListState<T> {
  loading: boolean;
  started: boolean;
  elements: T[];
  skip: number;
  total: number;
}

export function listStore<T>(
  stateKey: StateKey<ListState<T>>,
  platformId: object,
  transferState: TransferState,
  fetch: (abort: AbortSignal) => Promise<{ elements: T[]; total: number }>,
): Signal<ListState<T>> {
  const initialValue = transferState.get(stateKey, {
    started: false,
    loading: true,
    elements: [],
    skip: 0,
    total: 0,
  });

  const listStore = signal<ListState<T>>(initialValue);

  if (!transferState.hasKey(stateKey)) {
    fetch(new AbortController().signal).then((result) => {
      listStore.update((state) => ({
        ...state,
        loading: false,
        started: true,
        elements: [...state.elements, ...result.elements],
        skip: state.skip + result.elements.length,
        total: result.total,
      }));
      //transferState.set(stateKey, listStore());
    });
  }

  return listStore.asReadonly();
}
