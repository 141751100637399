import {
  computed,
  effect,
  Signal,
  signal,
  StateKey,
  TransferState,
} from '@angular/core';
import { ListState } from './listStore';
import { FilteredAggregation, FilteredResult } from './store-api.service';
import { isPlatformServer } from '@angular/common';

export interface AppliedFilter {
  type: string;
  values: { id: string; label: string }[];
}

export interface AppliedQuery {
  type: string;
  values: FilterQueryValue[];
}

export interface FilterQueryValue {
  id: string;
  label: string;
}

export function serializeFilterQueryValue(value: FilterQueryValue): string {
  return `${value.label}:${value.id}`;
}

export interface FilterState {
  appliedQuery: AppliedQuery[];
}

export interface FilteredListState<Filter extends FilterState, Result>
  extends ListState<Result> {
  filter: Filter | null;
  appliedFilters: AppliedFilter[];
  availableFilters: AvailableFilter[];
}

export interface AvailableFilter {
  id: string;
  label: string;
  type: string;
  options: AvailableFilterOption[];
}

export interface AvailableFilterOption {
  id: string;
  label: string;
  position: number;
  count: number;
}

export interface LazyLoadedListStore<Result> extends ListState<Result> {
  loadMore(): Promise<void>;
}

export interface FilteredListStore<Filter extends FilterState, Result>
  extends FilteredListState<Filter, Result>,
    LazyLoadedListStore<Result> {}

export function filteredListStore<Filter extends FilterState, Result>(
  stateKey: StateKey<FilteredListState<Filter, Result>>,
  platformId: object,
  transferState: TransferState,
  filterStore: Signal<Filter>,
  fetch: (
    filter: Filter,
    skip: number,
    signal: AbortSignal,
  ) => Promise<FilteredResult<Result>>,
): Signal<FilteredListStore<Filter, Result>> {
  const filter = filterStore();

  let currentFilter = {
    key: JSON.stringify(filter),
    value: filter,
  };

  const initialState = transferState.get(stateKey, {
    started: false,
    loading: false,
    elements: [],
    skip: 0,
    filter: null,
    total: 0,
    availableFilters: [],
    appliedFilters: [],
  });

  const listStore = signal<FilteredListState<Filter, Result>>(initialState);

  function loadInitial(filter: { key: string; value: Filter }) {
    if (isPlatformServer(platformId)) {
      return;
    }

    fetch(filter.value, 0, new AbortController().signal).then((result) => {
      if (currentFilter.key !== filter.key) {
        return;
      }

      listStore.update((state) => {
        return {
          ...state,
          filter: filter.value,
          loading: false,
          started: true,
          elements: result.elements,
          skip: result.elements.length,
          total: result.total,
          appliedFilters: filter.value.appliedQuery,
          availableFilters: getAvailableFilters(
            filter.value.appliedQuery,
            result.aggregations,
          ),
        };
      });
      //transferState.set(stateKey, listStore());
    });
  }

  effect(() => {
    const newFilter = filterStore();
    const newFilterKey = JSON.stringify(newFilter);
    if (currentFilter.key === newFilterKey) {
      return;
    }

    currentFilter = { key: newFilterKey, value: newFilter };

    loadInitial(currentFilter);
  });

  if (!transferState.hasKey(stateKey)) {
    loadInitial(currentFilter);
  }

  return computed<FilteredListStore<Filter, Result>>(() => {
    const store = listStore();

    return {
      ...store,
      loadMore() {
        const store = listStore();
        if (store.loading && !store.started) {
          return Promise.resolve(); // TODO replace
        }

        const filter = currentFilter;
        const abort = new AbortController();
        const promise = fetch(filter.value, store.skip, abort.signal).then(
          (result) => {
            if (currentFilter.key !== filter.key) {
              return;
            }

            listStore.update((state) => {
              return {
                ...state,
                loading: false,
                elements: [...state.elements, ...result.elements],
                skip: state.skip + result.elements.length,
                total: result.total,
                appliedFilters: filter.value.appliedQuery,
                availableFilters: getAvailableFilters(
                  filter.value.appliedQuery,
                  result.aggregations,
                ),
              };
            });
          },
        );

        listStore.update((state) => {
          return {
            ...state,
            loading: true,
            fetch: promise,
          };
        });

        return promise;
      },
    };
  });
}

const hiddenGroups = [
  'Vorteile',
  'Höhe',
  'Umtopf- Empfehlung in',
  'Familie',
  'Topfdurchmesser',
];

function getAvailableFilters(
  appliedFilters: AppliedQuery[],
  agg: FilteredAggregation,
): AvailableFilter[] {
  const filters: AvailableFilter[] = [];

  // const stockFilter: AvailableFilter = {
  //   label: 'Liefertermin',
  //   id: 'stock',
  //   options: [],
  //   queryParam: 'stock',
  // };
  // for (const stock of agg.stock.buckets) {
  //   stockFilter.options.push({
  //     label: stock.key,
  //     count: stock.count,
  //     position: parseInt(stock.key),
  //     id: stock.key,
  //   });
  // }
  // stockFilter.options.sort((first, second) => second.position - first.position);
  // filters.push(stockFilter);

  if (!agg.filters) {
    return [];
  }

  for (const group of agg.filters.buckets) {
    if (hiddenGroups.some((g) => g === group.key)) {
      continue;
    }

    const appliedFilter = appliedFilters.find((a) => a.type === group.key);
    const groupEntity = group.options.entities[0];
    if (!groupEntity) {
      continue;
    }

    const filter: AvailableFilter = {
      id: groupEntity.groupId,
      label: group.key,
      options: [],
      type: group.key,
    };
    const entity = agg.properties.buckets.find((b) => b.key === filter.id);

    for (const property of group.options.entities) {
      if (
        appliedFilter &&
        appliedFilter.values.some((v) => v.id === property.id)
      ) {
        continue;
      }

      filter.options.push({
        id: property.id,
        label: property.name,
        position: property.position,
        count:
          entity?.options.buckets.find((b) => b.key === property.id)?.count ??
          0,
      });
    }

    filter.options.sort((first, second) => {
      const diff = first.position - second.position;
      if (diff === 0) {
        return first.label.localeCompare(second.label);
      }
      return diff;
    });

    if (filter.options.length > 0) {
      filters.push(filter);
    }
  }

  return filters;
}
