export const environment = {
  storefrontUrl: 'https://zimmerpflanzen.de',
  accessToken: 'SWSCY0HNRDR5AEJPYKZMQZQ2SA',
  baseUrl: 'https://zimmerpflanzen.de',
  stripePublicKey:
    'pk_live_51OHiIkCeV4cSfW5SPsHCNhMnTLcwCKNMlgLtcRRv98OQVt3ywszZyBzj6j46C3vj58vK3fVKU5J0z63o1Qa7NO0z00H4Vpx6uL',
  customEvents: true,
  categories: {
    pot: '018f3924038f766a814a0f76cf49f7ad',
    plant: '018f3923a7017617bd38092951a9ea99',
    accessory: '018f39241d5d7db694ed56ffcdbf61de',
  },
  version: '29c6d0fb326aba6654a3788051e423d7bd1fcc52',
  env: 'production',
};
