import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private lastEvent: string | null = null;

  httpClient = inject(HttpClient);
  platformId = inject(PLATFORM_ID);
  sessionId = Math.random().toString(36).substring(2);

  push(evt: string, data: unknown) {
    if (!isPlatformBrowser(this.platformId) || !environment.customEvents) {
      if (evt === 'error') {
        console.error(data);
      }
      return;
    }
    const newEvent = {
      id: Math.random().toString(36),
      type: evt,
      data: data ?? {},
      time: new Date(),
      lastEvent: this.lastEvent,
      referrer: document.referrer,
      clientWidth: window.innerWidth,
      clientHeight: window.innerHeight,
      sessionId: this.sessionId,
      agent: navigator.userAgent,
      url: window.location.href,
    };
    this.lastEvent = newEvent.id;
    this.httpClient
      .post('/custom-api/event', newEvent, {
        responseType: 'text',
        observe: 'response',
      })
      .subscribe();
  }
}
